<template>
	<div class="ui-table">
		<a-table
			:rowKey="rowKey" :loading="loading" :columns="columns" :dataSource="isRequest ? list : dataSource"
			:pagination="isPage ? pagination : false" :scroll="scroll" :customRow="customRow" :size="size" :rowClassName="rowClassName"
			:bordered="bordered" @resizeColumn="handleResizeColumn" @change="changeSort"
			:rowSelection="rowSelectionType ? { type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll } : null"
		>
			<template #headerCell="cell">
				<slot name="headerCell" v-bind="isPage ? {...cell, pagination: pagination } : cell"></slot>
			</template>
			<template #bodyCell="cell">
				<slot name="bodyCell" v-bind="isPage ? {...cell, pagination: pagination } : cell"></slot>
			</template>
			<template #footerCell="cell">
				<slot name="footerCell" v-bind="isPage ? {...cell, pagination: pagination } : cell"></slot>
			</template>
			<template #summary v-if="hasSummary">
				<slot name="summary" v-bind="isPage ? {...dataSummary, pagination: pagination } : cell">
					<a-table-summary-row v-if="isRequest ? list.length : dataSource.length">
						<a-table-summary-cell v-for="(item, index) in columns" :key="item.title">
							<div v-if="item.summaryKey">{{ summaryIndex >= 0 ? dataSummary[item.summaryKey] : summaryData[item.summaryKey] }}</div>
							<div v-else-if="index === 0" class="ui-summary">合计</div>
						</a-table-summary-cell>
					</a-table-summary-row>
				</slot>
			</template>
		</a-table>
	</div>
</template>

<script>
	export default {
		emits: ['rowSelected', 'dragEnd', 'pageChange'],
		props: {
			columns: {  // 表格数据列，与antd table用法一致，额外增加 summaryKey 字段用于总结列展示
				type: Array,
				default: () => []
			},
			dataSource: {  // 传入的列表数据
				type: Array,
				default: () => []
			},
			searchData: {  // 请求接口时携带的参数
				type: Object,
				default: () => {}
			},
			isRequest: {  // 是否组件调用方法，true时 requestFun必填
				type: Boolean,
				default: false
			},
			requestFun: {  // 列表请求函数
				type: Function,
				default: () => {}
			},
			totalCount: {
				type: Number,
				default: 0
			},
			scroll: {
				default: Object,
				default: () => {}
			},
			draggable: {  //是否开启拖拽
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: 'default'
			},
			bordered: {
				type: Boolean,
				default: false
			},
			rowSelectionType: { // radio   checkbox
				type: String,
				default: ''
			},
			isPage: {  //是否需要分页
				type: Boolean,
				default: true
			},
			rowKey: {
				type: String,
				default: 'id'
			},
			rowClassName:{
				type: Function,
				default: () => {}
			},
			hasSummary: {  // 底部是否有总结行
				type: Boolean,
				default: false
			},
			summaryData: {  // 外部传入的总结数据；若外部传入，则 summaryIndex 必须设置为-1，否则无效
				default: Object,
				default: () => {}
			},
			summaryIndex: {  //接口返回的总结数据固定在第几个；-1则代表总结项为外部传入，不在接口返回
				type: Number,
				default: -1
			},
			isReport: { // 是否将接口数据上报给父组件,父组件通过reporting监听函数接收
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				list: [],
				selectedRowKeys: [],
				selectedRowData: [],
				sourceIndex: -1,
				sorterList:[],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				dataSummary: {}
			}
		},
		created() {
			if (this.isRequest) {
				// this.getData();
			}
		},
		update() {
			if (!this.isRequest) {
				this.pagination.total = this.totalCount || this.dataSource.length;
			}
		},
		methods: {
			getData() {
				this.$nextTick(async () => {
					if (!this.isRequest) {
						this.$emit('pageChange', {
							page: this.pagination.current,
							pageSize: this.pagination.pageSize,
						})
						return;
					};
					this.loading = true;
					try {
						let ret = await this.requestFun({
							page: this.isPage ? this.pagination.current : undefined,
							pageSize: this.isPage ? this.pagination.pageSize : undefined,
							...this.searchData
						});
						this.loading = false;
						if (ret.code === 200) {
							this.list = [];
							if (this.isPage) {
								if (ret.data.pageInfo) {
									this.pagination.total = ret.data.pageInfo.totalCount;
								} else {
									this.pagination.total = ret.data.totalCount;
								}
							}
							let list = [];
							if (this.isReport) {
								this.$emit('reporting', ret.data);
							}
							if (ret.data.pageInfo) {
								list = ret.data.pageInfo.list
							} else {
								list = ret.data.list || ret.data;''
							}
							if (this.hasSummary) {
								if (this.summaryIndex >= 0) {
									this.dataSummary = list.splice(this.summaryIndex, 1)[0];
								}
							}
							this.list = list;
							this.$nextTick(() => {
								this.$forceUpdate();
							});
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			toQuery() {
				// 重置分页刷新
				this.pagination.current = 1;
				this.getData();
			},
			refQuery() {
				// 不重置分页刷新
				this.getData();
			},
			handleResizeColumn(w, col) {
				col.width = w;
			},
			customRow(record, index) {
				return {
					draggable: this.draggable,
					style: {
						cursor: this.draggable ? 'pointer' : '',
						'font-size': '12px'
					},
					onDragstart: (event) => {
						if (!this.draggable) return;
						// 兼容IE
						var ev = event || window.event
						// 阻止冒泡
						ev.stopPropagation()
						// 得到源目标数据
						this.sourceIndex = index;
					},
					onDragover: (event) => {
						if (!this.draggable) return;
						// 兼容 IE
						var ev = event || window.event
						// 阻止默认行为
						// this.sourceIndex = -1;
						ev.preventDefault()
					},
					onDrop: (event) => {
						if (!this.draggable) return;
						if (this.sourceIndex === -1) return;
						// 兼容IE
						var ev = event || window.event
						// 得到目标数据
						// this.targetObj = record;
						if (this.isRequest) {
							let itemData = this.list.splice(this.sourceIndex, 1);
							if (itemData) {
								this.list.splice(index, 0, itemData[0]);
							}
							this.$emit('dragEnd', this.list);
						} else {
							let itemData = this.dataSource.splice(this.sourceIndex, 1);
							this.dataSource.splice(index, 0, itemData[0]);
							this.$emit('dragEnd', this.dataSource);
						}
						this.sourceIndex = -1;
						// 阻止冒泡
						ev.stopPropagation()
					}
				}
			},
			onSelectChange(record, selected) {
				if (this.rowSelectionType === 'checkbox') {
					if (selected) {
						this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record[this.rowKey]);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record[this.rowKey]), 1);
						this.selectedRowData = this.selectedRowData.filter(item => {
							return item[this.rowKey] !== record[this.rowKey];
						});
					}
				} else {
					if (selected) {
						this.selectedRowData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record[this.rowKey]];
					} else {
						this.selectedRowData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
				this.$emit('rowSelected', {
					keys: this.selectedRowKeys,
					list: this.selectedRowData
				});
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item[this.rowKey]) === -1) {
							this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item[this.rowKey]);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item[this.rowKey]) !== -1) {
							this.selectedRowData.splice(this.selectedRowKeys.indexOf(item[this.rowKey]), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item[this.rowKey]), 1);
						}
					})
				}
				this.$emit('rowSelected', {
					keys: this.selectedRowKeys,
					list: this.selectedRowData
				});
			},
			clearSelectData() {
				this.selectedRowKeys = [];
				this.selectedRowData = [];
				
				this.$emit('rowSelected', {
					keys: this.selectedRowKeys,
					list: this.selectedRowData
				});
			},
			//调接口排序
			changeSort(page, filters, sorter,extra){
				if(extra.action == 'sort'){
					this.$emit('changeSort',sorter?.order || null,sorter.columnKey);
				}
			}
		}
	}
</script>

<style scoped>
	.ui-table {
		padding-top: 10px;
	}
	.ui-summary {
		font-weight: 600;
	}
</style>
